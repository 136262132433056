<template>
	<div>

		<h1 class="text-2xl font-bold mb-5">{{ tab | sentenceCase }} Card Payments</h1>

		<div class="grid grid-cols-2 mb-4">
			<div class="col-span-1">
				<tabs :tabs="tabs" v-model="tab" />
			</div>
		</div>

		<component :is="tab" />

	</div>
</template>
<script>
	export default {
		components: {
			credit: () => import('./Personal'),
			corporate: () => import('./Corporate'),
		},
		data() {
			return {
				tab: 'credit',
				tabs: [
					{ name: 'credit', title: 'Credit Card' },
					{ name: 'corporate', title: 'Corporate' },
				]
			}
		},
	}
</script>