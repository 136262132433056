<template>
    <component :is="appMode"></component>
</template>

<script>
    export default {
        components: {
            super: require('./super/Index.vue').default,
            staff: require('./Staff.vue').default,
            company: require('./Company.vue').default,
        }
    }
</script>